export default function HeroSection() {
    return <>
        <div className="flex flex-row gap-20 max-md:gap-4 w-full justify-end max-md:flex-col-reverse">

            {/* Textos */}
            <div className="flex flex-col items-start md:mt-32 max-md:w-full px-4">
                <h2 className="text-4xl">Francesc J. López Cànoves</h2>
                <h4 className="text-2xl">Arreglista Musical</h4>

                <h4 className="text-sm mt-6 max-w-4xl max-md:w-full">Me suelen llamar Cesc, nací en el año 96 en Llíria, Valencia. Me crié estudiando música y pasé toda mi adolescencia tocando en bandas de calle, xarangas y en un abanico diverso de fiestas patronales y culturales. Junto con mi xaranga la 'Melomanía' hemos tocado los arreglos que puedes encontrar y adquirir en los enlaces de ésta página, donde te muestro uno de los ocios que más me gusta y que estoy llevando a cabo con dedicación y por la música. La mayoria de ellos son para Xaranga pero espero en un futuro poder arreglar e incluso componer mis propias marchas y temas, tanto para banda como para diferentes agrupaciones.</h4>
            </div>
            
            {/* Imagen */}
            <div className="rounded-bl-custom overflow-hidden max-h-96">
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img 
                    className="w-full"
                    draggable={false} 
                    src="DALLE_2023-08-25_12.30.16_-_do_a_wallpaper_of_an_real_elegant_music_composer_.jpg"
                />
            </div>
        </div>
    </>
}