import ItemBig from "./ItemBig"
import ItemSmall from "./ItemSmall"

type ItemProps = {
    image: string;
    title: string;
    description: string;
    paypalID: string;
    price: string;
}

const small: ItemProps[] = [
    { image: "edmundo_arias.jpg", title: "El mecánico - Edmundo Arias", description: "Porro Colombiano", paypalID: "T8RUK59NPKDH2", price: "25,00 €" },
    { image: "shakira.jpg", title: "La tortura - Shakira", description: "La tortura - Shakira", paypalID: "8SWZSWM4J9XRL", price: "35,00 €" },
    { image: "Nathy-Peluso-1-1000x694.jpg", title: "Mafiosa - Nathy Peluso", description: "Mafiosa - Nathy Peluso", paypalID: "PVDT28UX24Y4U", price: "45,00 €" },
    { image: "61PUSF8bgOL.png", title: "Llevarte - Los Calzones", description: "Llevarte - Los Calzones", paypalID: "VYQ7DD9UE534N", price: "35,00 €" },
    { image: "R.png", title: "Musikaren Doinua - En Tol Sarmiento", description: "Musikaren Doinua - En Tol Sarmiento", paypalID: "AMK54CTV7QE3J", price: "30,00 €" },
    { image: "zoo.png", title: "Tobogán - ZOO", description: "Tobogán - ZOO", paypalID: "3TSSQJEPWJJWY", price: "25,00 €" },
    { image: "OIP.png", title: "Esbarzers - La gossa sorda", description: "Esbarzers - La gossa sorda", paypalID: "A7Z4VC9W5VARN", price: "25,00 €" },
    { image: "ObrintPasLogo1.png", title: "i Si demà no tornara - Obrint pas", description: "i Si demà no tornara - Obrint pas", paypalID: "5VWGLUFXW2ZUQ", price: "35,00 €" },
    { image: "boikot.png", title: "Inés - Boikot", description: "Inés - Boikot", paypalID: "89MPR65YTAALE", price: "35,00 €" },
    { image: "814fb9f49b3529020d48e78085b1ad30.png", title: "La flama - Obrint Pas", description: "La flama - Obrint Pas", paypalID: "RB4T4XJ7LAQB6", price: "40,00 €" },
    { title: "Seven Nation Army - Ben L'Oncle Soul", price: "30,00 €", description: "Seven Nation Army - Ben L'Oncle Soul", paypalID: "FDMHFTQKTGA26", image: "OIP_2.png" },
    { title: "La Sanata Kolektiva - Los Calzones", description: "La Sanata Kolektiva - Los Calzones", price: "40,00 €", paypalID: "JTQGG2V64ALBU", image: "400x400cc.png" }

]

export default function Composiciones() {
    return <>
        <div className="w-14 h-0.5 bg-black mt-40"></div>
        <h3 className="text-3xl mt-1">Mis arreglos</h3>

        {/* Expositor */}
        <div className="flex md:flex-row gap-8 mt-12 w-3/5 max-md:flex-col-reverse max-md:w-full">
            
            <div
                className="flex flex-col md:w-1/2 gap-3"
            >
                {/* Lista */}
                {
                    small.map((item, key) => (
                        <ItemSmall 
                            key={key}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            price={item.price}
                            paypalID={item.paypalID}
                        />
                    ))
                }
            </div>

            <div
                className="relative md:w-1/2 h-full"
            >
                {/* Grande */}
                <ItemBig 
                    image={"altariel.jpeg"} 
                    title="Altàriel - Marcha Mora"
                    description="Marcha mora basada en la BSO del Señor de los Anillos de Howard Shore"
                    paypalID="2YKGG4RDBAHKA"
                    youtubeID="nDHGEpSpQoM"
                    price="70 €"
                />
            </div>

        </div>
    </>
}