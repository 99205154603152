import React from 'react';
import './App.css';
import HeroSection from './components/HeroSection';
import Composiciones from './components/Composiciones';
import Footer from './components/Footer';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="flex flex-col items-center" style={{ background: '#fafafa'}}>
      
      <HeroSection />

      <Composiciones />

      <div className='mb-24' />

      <Footer />
      <Analytics />      
    </div>
  );
}

export default App;
