/* eslint-disable jsx-a11y/heading-has-content */

import PaypalButton from "./PaypalButton";

export default function ItemSmall({
    image, 
    title, 
    description, 
    price,
    paypalID,
}: { 
    image?: string, 
    title?: string, 
    description?: string, 
    paypalID?: string,
    price?: string
}) {
    return <>
        <div className="relative w-full rounded-md flex flex-row gap-2 items-center max-md:px-2 justify-center">
            <div 
                className="h-fit w-2/5 flex flex-row justify-center overflow-clip max-md:max-w-min aspect-1"
                style={{
                    minWidth: '120px'
                }}
            >
                <img 
                    className="h-full rounded-md"
                    src={image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR9MzPvHYdQzO2kQnQCxWkHDGwUxhP825VEA&usqp=CAU"} 
                    alt=""
                />
            </div>
            <div
                className="flex flex-col justify-between w-3/5 h-full"
            >
                <div className="flex flex-col w-full">
                    <h3 className="text-2xl rounded-md">{title || "No title"}</h3>
                    <h3>{price || "Free"}</h3>
                    <h3 className="text-sm rounded-md mt-2 text-gray-600">{description || "No description"}</h3>
                    <h3 className="text-gray-500 rounded-md"></h3>
                </div>

                {
                    paypalID &&
                    <PaypalButton paypalID={paypalID} />
                }
            </div>
        </div>
    </>
}