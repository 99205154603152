/* eslint-disable jsx-a11y/heading-has-content */

import PaypalButton from "./PaypalButton";

export default function ItemBig({
    image, 
    title, 
    description, 
    price,
    paypalID,
    youtubeID
}: { 
    image?: string, 
    title?: string, 
    description?: string, 
    paypalID?: string,
    price?: string,
    youtubeID?: string
}) {
    return <>
        <div className="p-5">
            <div className="w-full inline-grid rounded-md overflow-clip aspect-1">
                {
                    !youtubeID && image &&
                    <img className="h-full" src={image || "altariel.jpeg"} alt="" />
                }
                {
                    youtubeID !== undefined &&
                    <>
                        <iframe 
                        className="w-full h-full"
                        src="https://www.youtube.com/embed/nDHGEpSpQoM" 
                        title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen></iframe>
                    </>
                    
                }
            </div>

            <div className="w-full">
                <h3 className="text-2xl rounded-md">{title || "No title"}</h3>
                <h3>{price || "Free"}</h3>
                <h3 className="text-sm rounded-md mt-2 text-gray-600">{description || "No description"}</h3>
                <h3 className="text-gray-500 rounded-md"> </h3>
            </div>

            {
                paypalID &&
                <PaypalButton paypalID={paypalID} />
            }
        </div>       
    </>
}